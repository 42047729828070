<template>
  <div class="ContainerUser">
    <!-- 基本设置 -->
    <h1 class="titleUser">基本设置</h1>
    <div class="userinfo">
      <!-- 默认表单 -->
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        class="login-form"
        id="components-form-demo-normal-login"
        hideRequiredMark
      >
        <a-form-model-item label="用户名" prop="username">
          <a-input v-model="form.username" class="CaptchaIpnt" placeholder="请输入用户名">
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="form.name" class="CaptchaIpnt" placeholder="请输入姓名">
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号">
          <a-input class="phoneInput" disabled v-model="form.phone"> </a-input>
          <a class="changPhone" @click="showModal">修改手机号</a>
        </a-form-model-item>
        <a-form-model-item label="学校ID">
          <a-input placeholder="学校ID" disabled v-model="form.schoolNum"> </a-input>
        </a-form-model-item>
      </a-form-model>

      <!-- 请扫码绑定 -->
      <div class="scan-qrcode" v-if="binding">
        微信号未绑定，<span @click="bundingWx">请扫码绑定</span>
      </div>
      <!-- 请解除绑定 -->
      <div class="scan-qrcode" v-else>
        微信号已绑定，<span @click="unbundingWx">解除绑定</span>
      </div>
      <!-- 二维码容器 -->
      <div class="qrCodeWx-dust">
        <div ref="qrCodeWx"></div>
        <div class="dust d-f f-d a-c j-c" v-if="codeFail">
          <p>二维码已失效</p>
          <a-button type="primary" @click="bundingWx">点击刷新</a-button>
        </div>
      </div>

      <!-- 更新信息 -->
      <a-button type="primary" class="update" @click="update">更新信息</a-button>
    </div>
    <!-- 头像 -->
    <div class="headPortrait">
      <h3>头像</h3>
      <img src="@/assets/img/headportrait.png" v-if="avatar === null || avatar === ''" />
      <img :src="avatar" class="avatar" v-else />
      <a-upload
        name="file"
        :showUploadList="false"
        :customRequest="customRequest"
        :headers="headers"
        :before-upload="beforeUpload"
        @change="handleChange"
        accept=".jpg, .png, .jpeg"
      >
        <a-button><a-icon type="upload" />上传图片</a-button>
      </a-upload>
    </div>
    <!-- 修改手机号 对话框 -->
    <a-modal
      cancelText="关闭"
      okText="下一步"
      v-model="visible"
      title="修改手机号"
      @ok="handleOk"
      @cancel="handleCancel"
      class="repair-phone"
    >
      <!-- 新手机号 -->
      <a-form-model ref="ruleEditForm" :model="editForm" :rules="editRules">
        <a-form-model-item prop="newPhone">
          <a-input
            v-model="editForm.newPhone"
            placeholder="请输入新手机号"
            class="setPhone"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="code">
          <a-input v-model="editForm.code" class="setCode" placeholder="请输入验证码">
          </a-input>
          <span id="setBtn">
            <a-button @click="sendVerifyCode" v-if="isSend">获取验证码</a-button>
            <a-button v-if="!isSend" disabled>{{ count }}s后可再次发送</a-button>
          </span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 修改手机号成功 对话框 -->
    <a-modal
      cancelText="关闭"
      okText="完成"
      v-model="visibleSuccess"
      title="修改手机号"
      destroyOnClose
      @ok="() => (visibleSuccess = false)"
      class="repair-success"
    >
      <div class="successModal d-f f-d a-c j-c">
        <img src="@/assets/img/success.png" alt="" />
        <p>修改成功</p>
      </div>
      <div slot="footer">
        <a-button
          type="primary"
          @click="() => (visibleSuccess = false)"
          class="button-success"
          >完成</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import Client from "@/oss/client";
import QRCode from "qrcodejs2";
import { v4 as uuidv4 } from "uuid";
export default {
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: "",
        username: "",
        phone: "null",
        schoolNum: "null",
      },
      editForm: {
        newPhone: "",
        code: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[A-Za-z][0-9A-Za-z]{2,12}$/;
              if (!reg.test(value)) {
                callbackFn("用户名只能包括数字(不以数字开头)和字母且在3~12位之间");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      editRules: {
        newPhone: [
          {
            required: true,
            message: "请输入手机号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
              if (!reg.test(value)) {
                callbackFn("手机号码格式错误");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^\d{4}$/;
              if (!reg.test(value)) {
                callbackFn("验证码格式错误");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
      },
      visible: false, //控制修改手机对话框
      visibleSuccess: false, //控制修改手机成功对话框
      required: false, //是否必填
      avatar: null,
      id: "", //用户id
      openId: "",
      headers: {
        authorization: "authorization-text",
        token: this.$ls.get("token"),
      },
      dataObj: {},
      //新手机
      count: 60,
      isSend: true,
      timer: null,
      binding: true,
      timer1: null,
      codeFail: false,
    };
  },
  watch: {
    avatar(val) {
      this.$emit("getAvatarChange", this.avatar);
    },
  },
  methods: {
    //修改手机号
    showModal() {
      this.visible = true;
      this.editOldForm.oldPhone = this.form.phone;
    },
    //修改手机号下一步
    handleOk() {
      this.$refs.ruleEditForm.validate((valid) => {
        if (valid) {
          this.$api
            .verifyCode({
              phone: this.editForm.newPhone,
              smsCode: this.editForm.code,
              smsCodeType: 4,
            })
            .then((res) => {
              if (res.success) {
                this.visible = false;
                this.visibleSuccess = true;
                this.form.phone = this.editForm.newPhone;
              } else {
                this.$message.error(res.message);
              }
            });
        }
      });
    },
    //发送验证码新手机
    sendVerifyCode() {
      this.$refs.ruleEditForm.validateField("newPhone", (valid) => {
        if (!valid) {
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.isSend = false;
            //这里可以插入$axios调用后台接口
            this.$api
              .sendSmsCode({ phone: this.editForm.newPhone, smsCodeType: 4 })
              .then((res) => {
                this.$message.info(res.message);
                if (res.success) {
                  return;
                }
              });

            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.isSend = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    //修改手机号取消
    handleCancel() {
      this.visible = false;
      this.EditForm = {
        newPhone: "",
        code: "",
      };
    },
    //更新信息
    update() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {};
          params.id = this.id;
          params.name = this.form.name;
          params.username = this.form.username;
          params.phone = this.form.phone;
          params.schoolNum = this.form.schoolNum;
          let userInfo = this.$ls.get("userInfo");
          if (params.phone === userInfo.phone && params.username === userInfo.username) {
            this.$api.updateInfo(params).then((res) => {
              if (res.success) {
                this.getUserInfo();
                this.$message.success("修改成功");
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            this.$api.updateInfo(params).then((res) => {
              if (res.success) {
                this.getUserInfo();
                this.$message.success("修改成功，请重新登录");
                this.$ls.set("token", null);
                this.$ls.set("userInfo", null);
                this.$store.commit("setList", []);
                this.$router.push("/login");
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
    //获取用户信息
    getUserInfo() {
      this.$api.getUserInfo().then((res) => {
        console.log(res);
        if (res.success) {
          this.$ls.set("userInfo", res.data);
          let userInfo = this.$ls.get("userInfo");
          this.form.schoolNum = userInfo.schoolNum;
          this.form.phone = userInfo.phone;
          this.form.name = userInfo.name;
          this.form.username = userInfo.username;
          this.id = userInfo.id;
          this.avatar = userInfo.avatar;
          this.openId = userInfo.openId;
          if (this.openId === "" || this.openId === null || this.openId === undefined) {
            this.binding = true;
          } else {
            this.binding = false;
          }
        }
      });
    },
    //获取Sts临牌
    getStsInfo() {
      //获取Token
      this.$api.getStsInfo().then((res) => {
        // console.log(res);
        if (res.success) {
          const {
            region,
            Bucket,
            AccessKeyId,
            AccessKeySecret,
            SecurityToken,
          } = res.data.data;
          this.dataObj = {
            region: region,
            bucket: Bucket,
            accessKeyId: AccessKeyId,
            accessKeySecret: AccessKeySecret,
            stsToken: SecurityToken,
          };
          return true;
        }
      });
    },
    //上传文件之前的操作
    beforeUpload(file) {
      this.getStsInfo();
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("您只能上传图片格式");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("文件不能大于5M");
      }
      return isLt5M && isJpgOrPng;
    },
    //上传文件
    handleChange(info) {},
    //上传图片到oss
    async customRequest(action) {
      try {
        const client = Client(this.dataObj),
          file = action.file;
        //修改命名
        var fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const random_name = "avatar/" + this.id + "_" + "avatar." + fileExtension;
        let result = await client.put(random_name, file);
        // console.log(result);
        if (result.res.statusCode === 200) {
          this.$api.updateAvatar({ avatar: random_name, id: this.id }).then((res) => {
            //更新成功
            if (res.success) {
              //获取头像
              this.$api.getAvatarUrl({ avatar: random_name }).then((res) => {
                // console.log(res);
                if (res.success) {
                  this.$message.success("上传成功");
                  this.getUserInfo();
                }
              });
            }
          });
        }
      } catch (error) {
        this.$message.error("上传失败");
      }
    },
    //微信解除绑定
    unbundingWx() {
      this.$confirm({
        title: "您确认解除绑定吗",
        onOk: () => {
          this.$api.unbundingWx({ userId: this.id }).then((res) => {
            // console.log(res);
            if (res.success) {
              this.getUserInfo();
            }
          });
        },
      });
    },
    //生成二维码
    bindQRCode(url) {
      new QRCode(this.$refs.qrCodeWx, {
        text: url,
        width: 140,
        height: 140,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    //绑定微信
    bundingWx() {
      this.codeFail = false;
      let uuid = uuidv4().replace(/-/g, "");
      this.$api.bundingWx({ uuid: uuid, userId: this.id }).then((res) => {
        if (res.success) {
          this.$refs.qrCodeWx.innerHTML = "";
          this.bindQRCode(res.data.url);
          this.timer1 = setInterval(() => {
            this.isBindSuccess(uuid);
          }, 2000);
        }
      });
    },
    //查看微信扫码状态
    isBindSuccess(uuid) {
      this.$api.isBindSuccess({ uuid: uuid }).then((res) => {
        console.log(res.data.ret);
        if (res.data.ret === 3) {
          this.getUserInfo();
          this.$refs.qrCodeWx.innerHTML = "";
          clearInterval(this.timer1);
        } else if (res.data.ret === 2) {
          this.codeFail = true;
          clearInterval(this.timer1);
        }
      });
    },
  },
  created() {
    this.getStsInfo();
    let userInfo = this.$ls.get("userInfo");
    this.form.schoolNum = userInfo.schoolNum;
    this.form.phone = userInfo.phone;
    this.form.name = userInfo.name;
    this.form.username = userInfo.username;
    this.id = userInfo.id;
    this.avatar = userInfo.avatar;
    this.openId = userInfo.openId;
    if (this.openId === "" || this.openId === null || this.openId === undefined) {
      this.binding = true;
    } else {
      this.binding = false;
    }
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.timer1);
  },
  //在页面离开时做的操作
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
};
</script>

<style lang="scss" scope>
@import "./setup.scss";
</style>
